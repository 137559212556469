import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getListaUnidade() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
	    	axios.get(`${consts.API_URL}/unidade/listarselect${
				getState().auth.usuario && getState().auth.usuario && getState().auth.usuario.id_cliente 
				? `?id_cliente=${getState().auth.usuario.id_cliente}` 
				: ''
			}`).then(resp => {
				dispatch({
			        type: 'PRINCIPAL_UNIDADE_LISTADO',
			        payload: resp
			    });
			}).catch(e => {
				setErroAPI(e);
			});
		}
	}
}

export function getListaUnidadeCarga() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/fatura/listarSimulacaoFatura${
			getState().usuario && getState().auth.usuario && getState().auth.usuario.id_cliente 
			? `?id_cliente=${getState().auth.usuario.id_cliente}` 
			: ''
		}`)
		.then(resp => {
			dispatch({
				type: 'PRINCIPAL_UNIDADE_CARGA_LISTADO',
		        payload: resp
			});		
			
		}).catch(async e => {
			console.log(e);			
		});
	}
}

export function getListaDemanda(id_unidade) {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
	    	axios.get(`${consts.API_URL}/demanda?id_unidade=${id_unidade}`).then(async resp => {

				let lista = [];
				for (let i = 0; i < resp.data.length; i++) {
					let registro = resp.data[i];

					await axios.get(`${consts.API_URL}/demandaitem?id_demanda=${registro.id}`)
					.then(resp => {
						lista.push({
							...registro,
							itens: resp.data || []
						})
					}).catch(async e => {
						setErroAPI(e);						
					});
				}
				dispatch({
					type: 'PRINCIPAL_DEMANDA_LISTADO',
					payload: { data: lista }
				});
			}).catch(e => {
				setErroAPI(e);
			});
		}
	}
}

export function getListaFatura(id_unidade, mes, ano) {
	return async (dispatch, getState) => {
		if (id_unidade && mes && ano) {
			await axios.get(`${consts.API_URL}/fatura?id_unidade=${id_unidade}`)
			.then(async resp => {
				let lista = [];
				for (let i = 0; i < resp.data.length; i++) {
					let registro = resp.data[i];

					if (registro.competencia == `${ano}-${mes}`) {

						await axios.get(`${consts.API_URL}/faturaitem?id_fatura=${registro.id}`)
						.then(resp => {
							lista.push({
								...registro,
								itens: resp.data || []
							})
						}).catch(async e => {
							setErroAPI(e);
						});
					}
				}
				dispatch({
					type: 'PRINCIPAL_FATURA_LISTADO',
					payload: { data: lista }
				});		
				
			}).catch(async e => {
				dispatch({
					type: 'PRINCIPAL_FATURA_LISTADO',
					payload: { data: [] }
				});

				setErroAPI(e);
				
			});
		} else {
			dispatch({
				type: 'PRINCIPAL_FATURA_LISTADO',
				payload: { data: [] }
			});
		}
	}
}