const userKey = '_servicos_user';
const INITIAL_STATE = {
	usuario: JSON.parse(localStorage.getItem(userKey)),
	validToken: false,
	modoTela: 'login',
	message: {
		open: false,
		text: '',
		time: 5000,
		type: 'success'
	},
	usuarioCarregado: null
}
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case 'LOGIN_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo
			};

		case 'TOKEN_VALIDATED':
			if (action.payload) {
				return { ...state, validToken: true }
			} else {
				localStorage.removeItem(userKey)
				return { ...state, validToken: false, usuario: null }
			}

		case 'USER_FETCHED':
			localStorage.setItem(userKey, JSON.stringify(action.payload))
			return { 
				...state, 
				usuario: {
					...action.payload, 
					acessoCliente: action.payload.id_usuario_tipo == 2
				},
				usuarioCarregado: action.payload, 
				validToken: true 
			}

		// case 'USER_CLIENTE_SELECIONADO':
		// 	let usuario = JSON.parse(localStorage.getItem(userKey));
		// 	if (usuario) {
		// 		localStorage.setItem(userKey, JSON.stringify({
		// 			...usuario,
		// 			id_cliente: action.payload || null
		// 		}));
		// 	}
		// 	return {
		// 		...state,
		// 		usuario: {
		// 			...usuario,
		// 			id_cliente: action.payload || null
		// 		}
		// 	}

		case 'USER_MESSEGE':
			return { ...state, message: action.payload }

        case 'USER_CARREGADO':
            return {
				...state,
				usuarioCarregado: action.payload.data
			};

		default:
			return state;

	}
}
