import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
	getListaUnidade, getListaUnidadeCarga
} from './dashboardActions';

class Carga extends Component {

	state = {
		pesquisar: '',
		indexBanner: 0,
		mes: String(new Date().getMonth() + 1).padStart(2, '0'),
		ano: String(new Date().getFullYear())
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {

		this.props.getListaUnidade();
		this.props.getListaUnidadeCarga();
		
    }

    render() {

		let meses = [
			{ id: '01', valor: 'Janeiro' }, { id: '02', valor: 'Fevereiro' }, { id: '03', valor: 'Março' }, { id: '04', valor: 'Abril' }, 
			{ id: '05', valor: 'Maio' }, { id: '06', valor: 'Junho' }, { id: '07', valor: 'Julho' }, { id: '08', valor: 'Agosto' }, 
			{ id: '09', valor: 'Setembro' }, { id: '10', valor: 'Outubro' }, { id: '11', valor: 'Novembro' }, { id: '12', valor: 'Dezembro' }
		];
		let anos = [];
		for (let ano = 2014; ano <= new Date().getFullYear(); ano++) {
			anos.push({ id: String(ano), valor: String(ano) });
		}

		return (
			<>
				{!this.state.unidade &&
				<div 
					style={{
						padding: 12
					}} >

					<Row alignCenter style={{ padding: 12, paddingRight: 24 }}>
						<Grid cols='12 12 6 4 3'>
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.voltar()} />
						</Grid>
					</Row>
						
					<div style={{ paddingTop: 8, paddingBottom: 8 }}>
						{!this.props.sessao.acessoCliente &&
						<div style={{ color: '#000', fontWeight: 'bold', fontSize: 18 }}>Cliente</div>}
						<div style={{ color: '#000', fontWeight: 'bold', fontSize: 18 }}>Unidade Consumidora</div>
					</div>

					<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />

					{this.props.listaUnidade.map(item => (
						<div key={item.id} 
							onClick={() => {
								this.setState({
									...this.state,
									unidade: {
										...item,
										configuracao: this.props.listaUnidadeCarga.filter(configuracao => configuracao.id_unidade == item.id)[0] || {}
									}
								});
							}} >
							<div style={{ paddingTop: 8, paddingBottom: 8 }}>
								{!this.props.sessao.acessoCliente &&
								<div style={{ color: '#000', fontSize: 16 }}>{item.nome_cliente}</div>}
								<div style={{ color: '#000', fontSize: 16 }}>{item.valor}</div>									
							</div>
							<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />
						</div>
					))}
				</div>}

				{this.state.unidade &&
				<div 
					style={{
						padding: 12
					}} >

					<Row alignCenter style={{ padding: 12, paddingRight: 24 }}>
						<Grid cols='12 12 6 4 3'>
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => {
									this.setState({
										...this.state,
										unidade: null
									});
								}} />
						</Grid>
					</Row>
					
					{/*TARIFAS*/}
					<div style={{ paddingTop: 16, paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row' }}>
						<div style={{ width: '60%', color: '#000', fontWeight: 'bold', fontSize: 16 }}>Tarifas</div>
						<div style={{ width: '40%', color: '#000', fontWeight: 'bold', textAlign: 'right' }}>Valor Unit.</div>
					</div>

					<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />

					{this.state.unidade.configuracao.tarifas.map(item => (
						<div key={item.id}>
							<div style={{ paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row' }}>
								<div style={{ width: '60%', color: '#000' }}>{item.descricao}</div>
								<div style={{ width: '40%', color: '#000', textAlign: 'right' }}>R$ {FormatUtils.formatarValorTela(item.valor_unitario, 6)}</div>									
							</div>
							<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />
						</div>
					))}
			
					{/*CARGAS*/}
					<div style={{ paddingTop: 16, paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row' }}>
						<div style={{ width: '60%', color: '#000', fontWeight: 'bold', fontSize: 16 }}>Cargas</div>
						<div style={{ width: '40%', color: '#000', fontWeight: 'bold', textAlign: 'right' }}>kW</div>
					</div>
					
					<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />
						
					{this.state.unidade.configuracao.cargas.map(item => (
						<div key={item.id}>
							<div style={{ paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row' }}>
								<div style={{ width: '60%', color: '#000' }}>{item.nome}</div>
								<div style={{ width: '40%', color: '#000', textAlign: 'right' }}>{FormatUtils.formatarValorTela(item.carga, 0)}</div>									
							</div>
							<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />
						</div>
					))}
				</div>}
			</>
		)
	}

	getCorFonte(hex) {
		if (!hex) {
			return null;
		}
		if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}
		// convert 3-digit hex to 6-digits.
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			throw new Error('Invalid HEX color.');
		}
		let r = parseInt(hex.slice(0, 2), 16);
		let g = parseInt(hex.slice(2, 4), 16);
		let b = parseInt(hex.slice(4, 6), 16);
	
		if ((r * 0.299 + g * 0.587 + b * 0.114) > 186) {
			return '#000000';
		} else {
			return '#FFFFFF';
		}
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.dashboard.aguardando,
	listaUnidade: state.dashboard.listaUnidade,
	listaUnidadeCarga: state.dashboard.listaUnidadeCarga
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaUnidade, getListaUnidadeCarga
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Carga)
