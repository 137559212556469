import React from 'react';
import Menu from './menu';
import imagemSoLogoMenuLateral from '../../assets/images/sologo_espacamento_menor.png';
import imagemEscritaLogoMenuLateral from '../../assets/images/logo_escrita_espacamento_menor.png';

export default props => (
	<aside className="main-sidebar elevation-4 sidebar-light-indigo">
		<a href="../../" className="brand-link navbar-light">
			<img src={imagemSoLogoMenuLateral} alt="Letsara Logo" className="brand-image" style={{ marginRight: 0 }} />
			<div style={{ display: 'flex', flexDirection: 'column', marginTop: 0 }}>
				<span className="brand-text font-weight-light">
					<img src={imagemEscritaLogoMenuLateral} alt="Letsara Logo" className="brand-image" style={{ marginLeft: 4 }} />
				</span>
			</div>
		</a>
		<div className="sidebar">
			<Menu />
		</div>
	</aside>
)
