const INITIAL_STATE = {
	aguardando: false,
	listaUnidade: [],
	listaUnidadeCarga: [],
	listaDemanda: [],
	listaFatura: []
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

		case 'DASHBOARD_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

		case 'PRINCIPAL_UNIDADE_LISTADO':
            return {
				...state,
				listaUnidade: action.payload.data
			};
			
		case 'PRINCIPAL_UNIDADE_CARGA_LISTADO':
			return {
				...state,
				listaUnidadeCarga: action.payload.data
			};

		case 'PRINCIPAL_DEMANDA_LISTADO':
            return {
				...state,
				listaDemanda: action.payload.data
			};

		case 'PRINCIPAL_FATURA_LISTADO':
            return {
				...state,
				listaFatura: action.payload.data
			};

        default:
            return state;
    }
}
