import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
	getListaUnidade, getListaUnidadeCarga
} from './dashboardActions';

class SimuladorFatura extends Component {

	state = {
		pesquisar: '',
		indexBanner: 0,
		mes: String(new Date().getMonth() + 1).padStart(2, '0'),
		ano: String(new Date().getFullYear()),
		lista: [],
		listaHorario: [],
		modoAdicionar: false,
		registro: null
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {

		this.props.getListaUnidade();
		this.props.getListaUnidadeCarga();

		let listaHorario = [];
		let dataHoraBase = new Date('2023/01/01 00:00');
		for (let i = 0; i < 24; i++) {

			listaHorario.push({
                posicao: i,
				id: `${String(dataHoraBase.getHours()).padStart(2, '0')}:${String(dataHoraBase.getMinutes()).padStart(2, '0')}`,
                valor: `${String(dataHoraBase.getHours()).padStart(2, '0')}:${String(dataHoraBase.getMinutes()).padStart(2, '0')}`
			});
			
			dataHoraBase.setMinutes(dataHoraBase.getMinutes() + 60);
        }

		this.setState({
			...this.state,
			listaHorario: listaHorario
		});
		
    }

	adicionar(registro) {

		const tarifas = this.state.unidade.configuracao.tarifas || [];
		const cargas = this.state.unidade.configuracao.cargas || [];

        let carga = cargas.filter(carga => carga.id == registro.id_carga)[0];
        let inicio = this.state.listaHorario.filter(inicio => inicio.id == registro.horario_inicio)[0];
        let fim = this.state.listaHorario.filter(fim => fim.id == registro.horario_fim)[0];
        let dataHoraInicio = new Date(`2023/01/01 ${inicio.valor}`);
        let dataHoraFim = inicio.posicao < fim.posicao ? new Date(`2023/01/01 ${fim.valor}`) : new Date(`2023/01/02 ${fim.valor}`);
        
        let quantidade = (parseInt(dataHoraFim.getTime() - dataHoraInicio.getTime()) / 1000 / 60 / 60) * carga.carga;
        
        let tarifaReservado = tarifas.filter(itemTarifa => itemTarifa.descricao.includes('ENERGIA RESERVADO'))[0];
        let tarifaPonta = tarifas.filter(itemTarifa => itemTarifa.descricao.includes('ENERGIA PONTA'))[0];
        let tarifaForaPonta = tarifas.filter(itemTarifa => itemTarifa.descricao.includes('ENERGIA FORA PONTA'))[0];
        
        let tipo = 'CONSUMO DE ENERGIA FORA PONTA';
        let tarifa = tarifaForaPonta ? tarifaForaPonta.valor_unitario || 0 : 0;
        
        if (/*unidade.periodo_reservado_inicio && unidade.periodo_reservado_fim*/
            tarifaReservado
            && ['21:00', '22:00','23:00', '00:00', '01:00', '02:00', '04:00', '05:00']
            .filter(horario => horario == inicio.valor).length) {
            tarifa = tarifaReservado.valor_unitario || 0;
            tipo = 'CONSUMO DE ENERGIA RESERVADO';
        } else if (/*unidade.periodo_ponta_inicio && unidade.periodo_ponta_fim*/
            tarifaPonta
            && ['18:00', '19:00','20:00']
            .filter(horario => horario == inicio.valor).length) {
                tarifa = tarifaPonta.valor_unitario || 0;
                tipo = 'CONSUMO DE ENERGIA PONTA';
        } else if (/*unidade.periodo_fora_ponta_inicio && unidade.periodo_fora_ponta_fim*/
            tarifaForaPonta
            && ['21:00', '22:00','23:00', '00:00', '01:00', '02:00', '04:00', '05:00', '06:00', '07:00','08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00']
            .filter(horario => horario == inicio.valor).length) {
            tarifa = tarifaForaPonta.valor_unitario || 0;
            tipo = 'CONSUMO DE ENERGIA FORA PONTA';
        }
        
        let total = parseFloat((quantidade * tarifa).toFixed(2));

        let lista = this.state.lista.map(item => item);
        
        lista.push({
            ...registro,
			id: new Date().getTime(),
            nome_carga: carga.nome,
            tipo: tipo,
            quantidade: quantidade,
            tarifa: tarifa,
            valor_total: total
        });
        this.setState({ 
            ...this.state,
            lista: lista,
			registro: null,
			modoAdicionar: false
        });

	}

    render() {

		let meses = [
			{ id: '01', valor: 'Janeiro' }, { id: '02', valor: 'Fevereiro' }, { id: '03', valor: 'Março' }, { id: '04', valor: 'Abril' }, 
			{ id: '05', valor: 'Maio' }, { id: '06', valor: 'Junho' }, { id: '07', valor: 'Julho' }, { id: '08', valor: 'Agosto' }, 
			{ id: '09', valor: 'Setembro' }, { id: '10', valor: 'Outubro' }, { id: '11', valor: 'Novembro' }, { id: '12', valor: 'Dezembro' }
		];
		let anos = [];
		for (let ano = 2014; ano <= new Date().getFullYear(); ano++) {
			anos.push({ id: String(ano), valor: String(ano) });
		}

		if (!this.state.unidade) {
			return (
				<>
					<div 
						style={{
							padding: 12
						}} >

						<Row alignCenter style={{ padding: 12, paddingRight: 24 }}>
							<Grid cols='12 12 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.voltar()} />
							</Grid>
						</Row>
							
						<div style={{ paddingTop: 8, paddingBottom: 8 }}>
							{!this.props.sessao.acessoCliente &&
							<div style={{ color: '#000', fontWeight: 'bold', fontSize: 18 }}>Cliente</div>}
							<div style={{ color: '#000', fontWeight: 'bold', fontSize: 18 }}>Unidade Consumidora</div>
						</div>

						<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />

						{this.props.listaUnidade.map(item => (
							<div key={item.id} 
								onClick={() => {
									this.setState({
										...this.state,
										unidade: {
											...item,
											configuracao: this.props.listaUnidadeCarga.filter(configuracao => configuracao.id_unidade == item.id)[0] || {}
										}
									});
								}} >
								<div style={{ paddingTop: 8, paddingBottom: 8 }}>
									{!this.props.sessao.acessoCliente &&
									<div style={{ color: '#000', fontSize: 16 }}>{item.nome_cliente}</div>}
									<div style={{ color: '#000', fontSize: 16 }}>{item.valor}</div>									
								</div>
								<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />
							</div>
						))}
					</div>
				</>
			);
		} else {

			if (this.state.modoAdicionar) {
				return (
					<>
						<div 
							style={{
								padding: 12
							}} >

							<Select 
								label='Carga'
								options={this.state.unidade.configuracao.cargas.map(item => ({ id: item.id ? parseInt(item.id) : null, valor: item.nome })) }
								value={this.state.registro.id_carga}
								onChange={value => {
									this.setState({ 
										...this.state, 
										registro: {
											...this.state.registro,
											id_carga: value 
										}
									});
								}} />
									
							<Select 
								label='Ligou'
								options={this.state.listaHorario.map(item => ({ id: item.id, valor: item.valor })) }
								value={this.state.registro.horario_inicio}
								onChange={value => {
									this.setState({ 
										...this.state, 
										registro: {
											...this.state.registro,
											horario_inicio: value 
										}
									});
								}} />
									
							<Select 
								label='Desligou'
								options={this.state.listaHorario.map(item => ({ id: item.id, valor: item.valor })) }
								value={this.state.registro.horario_fim}
								onChange={value => {
									this.setState({ 
										...this.state, 
										registro: {
											...this.state.registro,
											horario_fim: value 
										}
									});
								}} />
								
							<Select 
								label='Trabalhara em conjunto'
								options={this.state.unidade.configuracao.cargas.map(item => ({ id: item.id ? parseInt(item.id) : null, valor: item.nome })) }
								value={this.state.registro.id_carga_conjunto}
								onChange={value => {
									this.setState({ 
										...this.state, 
										registro: {
											...this.state.registro,
											id_carga_conjunto: value 
										}
									});
								}} />
							
							<Row alignCenter style={{ paddingRight: 4 }}>
								<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-4' style={{ paddingLeft: 0 }}>
									<Button
										text='Adicionar'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										disabled={!this.state.registro.id_carga || !this.state.registro.horario_inicio || !this.state.registro.horario_fim}
										event={() => {
											this.adicionar(this.state.registro);
										}} />
								</div>
								<div class='col-6 col-xs-6 col-sm-6 col-md-6 col-lg-4'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => {
											this.setState({
												...this.state,
												modoAdicionar: false, 
												registro: {}
											});
										}} />
								</div>
							</Row>								
							
						</div>
					</>
				);
			} else {
			
				let valorTotal = 0;

				return (
					<>
						<div 
							style={{
								padding: 12
							}} >

							<Row alignCenter style={{ paddingRight: 4 }}>
								<div class='col-8 col-xs-8 col-sm-8 col-md-8 col-lg-4' style={{ paddingLeft: 0 }}>
									<Button
										text='Adicionar Carga'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										event={() => {
											this.setState({ 
												...this.state, 
												modoAdicionar: true, 
												registro: {}
											});
										}} />
								</div>

								<div class='col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => {
											this.setState({
												...this.state,
												unidade: null,
												lista: []
											});
										}} />
								</div>
							</Row>
						
							<div style={{ paddingTop: 16, paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row' }}>
								<div style={{ width: '34%', color: '#000', fontWeight: 'bold', fontSize: 14 }}>Carga</div>
								<div style={{ width: '22%', color: '#000', fontWeight: 'bold', fontSize: 14, textAlign: 'center' }}>Unit.</div>
								<div style={{ width: '22%', color: '#000', fontWeight: 'bold', fontSize: 14, textAlign: 'center' }}>Qtd</div>
								<div style={{ width: '22%', color: '#000', fontWeight: 'bold', fontSize: 14, textAlign: 'right' }}>Total</div>
							</div>
								
							<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />

							{this.state.lista.map(item => {
									
								valorTotal += item.valor_total;

								return (
									<div key={item.id} style={{ paddingTop: 8 }}>
										<div style={{ paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row', paddingBottom: 8 }}>
											<div style={{ width: '34%', color: '#000', fontSize: 12 }}>{item.nome_carga}</div>
											<div style={{ width: '22%', color: '#000', fontSize: 12, textAlign: 'center' }}>{FormatUtils.formatarValorTela(item.tarifa, 6)}</div>
											<div style={{ width: '22%', color: '#000', fontSize: 12, textAlign: 'center' }}>{FormatUtils.formatarValorTela(item.quantidade, 0)}</div>
											<div style={{ width: '22%', color: '#000', fontSize: 12, textAlign: 'right' }}>{FormatUtils.formatarValorTela(item.valor_total, 2)}</div>
										</div>
										<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />
									</div>
								);
							})}
							<div style={{ paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row' }}>
								<div style={{ width: '40%', color: '#000', fontWeight: 'bold', fontSize: 14 }}>Total</div>
								<div style={{ width: '60%', color: '#000', fontWeight: 'bold', fontSize: 14, textAlign: 'right' }}>R$ {FormatUtils.formatarValorTela(valorTotal, 2)}</div>
							</div>

							{this.resumo()}
						</div>
					</>
				);
			}
		}
	}

	resumo() {
		let listaAgrupador = [];

		let demanda = 0;
		let cargas = this.state.unidade.configuracao.cargas || [];
		let tarifas = this.state.unidade.configuracao.tarifas || [];
		let demandaContratada = (this.state.unidade.configuracao.demandas || []).filter(item => item.competencia == `${new Date().getFullYear()}${String(new Date().getMonth() + 1).padStart(2, '0')}`)[0];
			
		(this.state.lista || []).forEach(item => {

			let cargaSelecionada = cargas.filter(carga => carga.id == item.id_carga)[0];
			let cargaConjuntoSelecionada = cargas.filter(carga => carga.id == item.id_carga_conjunto)[0];
			let demandaItem = (cargaSelecionada ? parseInt(cargaSelecionada.carga || 0) : 0) 
				+ (cargaConjuntoSelecionada ? parseInt(cargaConjuntoSelecionada.carga || 0) : 0);

			if (demandaItem > demanda) {
				demanda = demandaItem;
			}

			if (listaAgrupador.filter(agrupado => agrupado.tipo == item.tipo)[0]) {
				listaAgrupador = listaAgrupador.map(agrupador => {
					if (agrupador.tipo == item.tipo) {
						return {
							...agrupador,
							quantidade: agrupador.quantidade + item.quantidade,
							valor_total: agrupador.valor_total + item.valor_total
						};
					} else {
						return agrupador;
					}
				});
			} else {
				listaAgrupador.push({
					...item
				});
			}
		});
			
		if (demanda > 0) {
				
			let tarifaDemanda = tarifas.filter(itemTarifa => itemTarifa.descricao.includes('DEMANDA ATIVA FATURADA (KW)'))[0] ?
				parseInt(tarifas.filter(itemTarifa => itemTarifa.descricao.includes('DEMANDA ATIVA FATURADA (KW)'))[0].valor_unitario || 0)
				: 0;
					
			listaAgrupador.push({
				id: 'DEMANDA',
				tipo: 'DEMANDA',
				tarifa: tarifaDemanda,
				quantidade: demanda,
				valor_total: demanda * tarifaDemanda
			});

			if (demandaContratada && parseInt(demandaContratada.contratada) > 0 && demanda > parseInt(demandaContratada.contratada)) {

				let demandaUltrapassagem = demanda - parseInt(demandaContratada.contratada);

				listaAgrupador.push({
					id: 'DEMANDA_ULTRAPASSAGEM',
					tipo: 'DEMANDA ULTRAPASSAGEM',
					tarifa: tarifaDemanda * 2,
					quantidade: demandaUltrapassagem,
					valor_total: demandaUltrapassagem * (tarifaDemanda * 2)
				});
			} 
		}
			
		let valorTotal = 0;
		return (
			<div style={{ marginTop: 32 }}>
				
				<div style={{ fontSize: 16,fontWeight: 'bold', textAlign: 'center', backgroundColor: '#47639e', color: '#ffffff', padding: 1 }}>Resumo</div>
				
				<div style={{ paddingTop: 16, paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row' }}>
					<div style={{ width: '40%', color: '#000', fontWeight: 'bold', fontSize: 14 }}>Produto</div>
					<div style={{ width: '20%', color: '#000', fontWeight: 'bold', fontSize: 14, textAlign: 'center' }}>Unit.</div>
					<div style={{ width: '20%', color: '#000', fontWeight: 'bold', fontSize: 14, textAlign: 'center' }}>Qtd</div>
					<div style={{ width: '20%', color: '#000', fontWeight: 'bold', fontSize: 14, textAlign: 'right' }}>Total</div>
				</div>
				<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />
				{listaAgrupador.map(item => {
					
					valorTotal += item.valor_total;
					return (
						<div key={item.id} style={{ paddingTop: 8 }}>
							<div style={{ paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row', paddingBottom: 8 }}>
								<div style={{ width: '40%', color: '#000', fontSize: 12 }}>{item.tipo}</div>
								<div style={{ width: '20%', color: '#000', fontSize: 12, textAlign: 'center' }}>{FormatUtils.formatarValorTela(item.tarifa, 6)}</div>
								<div style={{ width: '20%', color: '#000', fontSize: 12, textAlign: 'center' }}>{FormatUtils.formatarValorTela(item.quantidade, 0)}</div>
								<div style={{ width: '20%', color: '#000', fontSize: 12, textAlign: 'right' }}>{FormatUtils.formatarValorTela(item.valor_total, 2)}</div>
							</div>
							<div style={{ height: 1, backgroundColor: '#e8e8e8' }} />
						</div>
					);
				})}
				<div style={{ paddingLeft: 4, paddingRight: 4, display: 'flex', flexDirection: 'row' }}>
					<div style={{ width: '40%', color: '#000', fontWeight: 'bold', fontSize: 14 }}>Total</div>
					<div style={{ width: '60%', color: '#000', fontWeight: 'bold', fontSize: 14, textAlign: 'right' }}>R$ {FormatUtils.formatarValorTela(valorTotal, 2)}</div>
				</div>
			</div>
		);
	}

	getCorFonte(hex) {
		if (!hex) {
			return null;
		}
		if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}
		// convert 3-digit hex to 6-digits.
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			throw new Error('Invalid HEX color.');
		}
		let r = parseInt(hex.slice(0, 2), 16);
		let g = parseInt(hex.slice(2, 4), 16);
		let b = parseInt(hex.slice(4, 6), 16);
	
		if ((r * 0.299 + g * 0.587 + b * 0.114) > 186) {
			return '#000000';
		} else {
			return '#FFFFFF';
		}
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.dashboard.aguardando,
	listaUnidade: state.dashboard.listaUnidade,
	listaUnidadeCarga: state.dashboard.listaUnidadeCarga
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaUnidade, getListaUnidadeCarga
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SimuladorFatura)
