import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Header from '../common/template/header';
import SideBar from '../common/template/sideBar';
import Footer from '../common/template/footer';
import Messages from '../common/msg/messages';
import imagemLogo1 from '../assets/parceiros/logo1.png';
import imagemLogo2 from '../assets/parceiros/logo2.png';
import imagemLogo3 from '../assets/parceiros/logo3.png';
import imagemLogo4 from '../assets/parceiros/logo4.png';

import Routes from './routes';

class App extends Component {

	state = {
		carregando: 0
	}

	constructor(props) {
		super(props);

		const idInterval = setInterval(() => {
			this.setState({ ...this.state, carregando: this.state.carregando + 1 })
			if (this.state.carregando > 4) {
				clearInterval(idInterval);
			}
		}, 3000);
    }

	render() {
		return (
			<HashRouter>
				{this.state.carregando < 4 ?

					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', top: '40%' }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<div style={{ textAlign: 'center' }}>
									<img src={[imagemLogo1, imagemLogo2, imagemLogo3, imagemLogo4][this.state.carregando]} style={{ height: 100, width: 283 }} />
								</div>
							</div>
						</div>
					</div>
				:
					<div className='wrapper'>
						<Header />
						<SideBar />
						<Routes usuarioCarregado={this.props.usuarioCarregado} />
						<Messages />
					</div>
				}
			</HashRouter>
		);
	}
}


const mapStateToProps = state => ({
	sessao: state.auth.usuario
});
export default connect(mapStateToProps, null)(App);
