import '../common/template/dependencies';
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from './app';
import Auth from '../auth/auth';
import { validateToken, carregarUsuario } from '../auth/authActions';

class AuthOrApp extends Component {

	constructor(props) {
		super(props);

		let idInterval = setInterval(() => {
			if (this.props.auth.usuario) {
				// this.props.carregarUsuario();
				clearInterval(idInterval);
			}
		}, 2000);
	}

	componentWillMount() {
		if(this.props.auth.usuario) {
			this.props.validateToken(this.props.auth.usuario.token);
		}
	}

	render() {
		const { usuario, validToken } = this.props.auth
		if(usuario && validToken) {
			axios.defaults.headers.common['authorization'] = usuario.token;
			return <App usuarioCarregado={this.props.usuarioCarregado}>{this.props.children}</App>;
		} else if(!usuario && !validToken) {
			return <Auth />;
		} else {
			return false;
		}
	}
}

const mapStateToProps = state => ({ 
	auth: state.auth,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ validateToken, carregarUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp);
