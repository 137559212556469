import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
	
} from './dashboardActions';

class Contato extends Component {

	state = {
		pesquisar: '',
		indexBanner: 0
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {

    }

    render() {

		return (
			<>
				<div 
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						alignItems: 'center',
						padding: 12
					}} >
					
					<div style={{ marginTop: 20, color: '#000', textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Telefone</div>
					<div style={{ color: '#000', textAlign: 'center', fontSize: 18 }}>(55) 3308-1881</div>
					<div style={{ marginTop: 20, color: '#000', textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>E-mail</div>
					<div style={{ color: '#000', textAlign: 'center', fontSize: 18 }}>vetoreletrotecnica@gmail.com</div>
					<div style={{ marginTop: 20, color: '#000', textAlign: 'center', fontSize: 16, fontWeight: 'bold' }}>Endereço</div>
					<div style={{ color: '#000', textAlign: 'center', fontSize: 18 }}>RS 522 KM 02</div>
					<div style={{ color: '#000', textAlign: 'center', fontSize: 18 }}>Distrito Industrial Oeste</div>
					<div style={{ color: '#000', textAlign: 'center', fontSize: 18 }}>Ijuí/RS</div>

				</div>
				<Row alignCenter style={{ padding: 12, paddingRight: 24 }}>
					<Grid cols='12 12 6 4 3'>
						<Button
							text='Voltar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.voltar()} />
					</Grid>
				</Row>
			</>
		)
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.dashboard.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({
	
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Contato)
