import './auth.css';
import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, signup, initForm, setModoTela, esqueciSenha, redefinirsenha } from './authActions';
import Row from '../common/layout/row';
import If from '../common/operator/if';
import Messages from '../common/msg/messages';
import Input from '../common/form/inputAuth';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { ThemeProvider } from '@material-ui/styles';
import imagemLogoReports from '../assets/images/logo_reports_branco.png';
import imagemLogo from '../assets/images/logo.png';
import imagemLogin from '../assets/images/logo_vertical.png';
import logo1 from '../assets/parceiros/logo1.png';
import logo2 from '../assets/parceiros/logo2.png';

class Auth extends Component {
	constructor(props) {
		super(props);
		this.state = {loginMode: true};
	}

	// changeMode() {
	// 	this.setState({ loginMode: !this.state.loginMode });
	// }

	onSubmit(values) {
		const { login, signup } = this.props;
		this.state.loginMode ? login(values) : signup(values);
	}

	getStyles() {
		return makeStyles((theme) => ({
			paper: {
				marginTop: theme.spacing(8),
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center'
			},
			avatar: {
				margin: theme.spacing(1),
				backgroundColor: theme.palette.secondary.main
			},
			form: {
				width: '100%',
				marginTop: theme.spacing(1),
			},
			submit: {
				margin: theme.spacing(3, 0, 2),
			},
		}));
	}

	getTheme() {

		return createMuiTheme({
			palette: {
				primary: {
					// light: será calculada com base em palette.primary.main,
					main: '#1f3269',
					// dark: será calculada com base em palette.primary.main,
					// contrastText: será calculada para contrastar com palette.primary.main
				},
				secondary: {
					light: '#0066ff',
					main: '#0044ff',
					// dark: será calculada com base palette.secondary.main,
					contrastText: '#ffcc00',
				},
				// Usado por `getContrastText()` para maximizar o contraste entre
				// o plano de fundo e o texto.
				contrastThreshold: 3,
				// Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
				// dois índices dentro de sua paleta tonal.
				// Por exemplo, mude de Red 500 para Red 300 ou Red 700.
				tonalOffset: 0.2,
			},
		});
	}

	render() {

		const classes = this.getStyles();

		const { loginMode } = this.state;
		const { handleSubmit } = this.props;

		return (
			<ThemeProvider theme={this.getTheme()}>
				<div style={{ backgroundColor: '#ffffff', height: window.innerHeight - 100 }}>
					<div style={{ 
						display: 'flex', 
						flexDirection: 'column',
						alignItems: 'center', 
						height: '100%',
						backgroundColor: '#ffffff'
					}}>
						<Container component="main" maxWidth="xs" style={{ width: '100%' }}>
							<CssBaseline />
							{/*<h1 style={{ color: '#fff', textAlign: 'center', paddingBottom: 20 }}>S I G</h1>*/}
							<div className={classes.paper} 
								style={{ 
									backgroundColor: '#fff', 									 
									paddingTop: 40, 									
									paddingBottom: 0, 
									borderRadius: 10, 
									textAlign: 'center' 
								}}>


								<h3>Login</h3>

								{this.props.modoTela == 'login' &&
								<div>
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.onSubmit(v))}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="usuario"
											label="E-mail"
											name="usuario"
											autoComplete="usuario"
											autoFocus
											textTransform='none'
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													usuario: e.target.value,
													app: true
												});
											})} />

										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="senha"
											label="Senha"
											type={this.state.senhaVisivel ? 'text' : 'password'}
											id="senha"
											autoComplete="current-password"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha: e.target.value,
													app: true
												});
											})}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => {
																this.setState({
																	...this.state,
																	senhaVisivel: !this.state.senhaVisivel
																});
															}}
													  		onMouseDown={() => {}} >
															{this.state.senhaVisivel ? <VisibilityOffIcon /> : <VisibilityIcon />}
														</IconButton>
													</InputAdornment>
												)
											}} />

										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											style={{ marginTop: 10 }}
											className={classes.submit} >
											Entrar
										</Button>
									</form>
								</div>}

								{this.props.modoTela == 'esqueciSenha' &&
								<div>
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.props.esqueciSenha(v))}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="login"
											label="Informe seu E-mail"
											name="login"
											autoComplete="login"
											autoFocus
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													login: e.target.value
												});
											})} />	

										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											className={classes.submit} >
											Receber Código
										</Button>
									</form>
								</div>}

								{this.props.modoTela == 'redefinirSenha' &&
								<div>
									<form className={classes.form} noValidate onSubmit={handleSubmit(v => this.props.redefinirsenha(v))}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="login"
											label="Informe seu E-mail"
											name="login"
											autoComplete="login"
											autoFocus
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													login: e.target.value
												});
											})} />
											
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="senha"
											label="Senha"
											type={this.state.senhaVisivel ? 'text' : 'password'}
											id="senha"
											autoComplete="current-password"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha: e.target.value
												});
											})}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => {
																this.setState({
																	...this.state,
																	senhaVisivel: !this.state.senhaVisivel
																});
															}}
													  		onMouseDown={() => {}} >
															{this.state.senhaVisivel ? <VisibilityOffIcon /> : <VisibilityIcon />}
														</IconButton>
													</InputAdornment>
												)
											}} />
											
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="senha_confirmacao"
											label="Confirme sua Senha"
											type={this.state.senhaVisivel ? 'text' : 'password'}
											id="senha_confirmacao"
											autoComplete="current-password"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													senha_confirmacao: e.target.value
												});
											})}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => {
																this.setState({
																	...this.state,
																	senhaVisivel: !this.state.senhaVisivel
																});
															}}
													  		onMouseDown={() => {}} >
															{this.state.senhaVisivel ? <VisibilityOffIcon /> : <VisibilityIcon />}
														</IconButton>
													</InputAdornment>
												)
											}} />
											
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="codigo"
											label="Código"
											type="numeric"
											id="codigo"
											autoComplete="current-password"
											onChange={(e => {
												this.props.initForm({
													...this.props.formularioValues,
													codigo: e.target.value
												});
											})} />

										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											className={classes.submit} >
											Redefinir Senha
										</Button>
									</form>
								</div>}

							</div>

							<Snackbar
								open={this.props.message.open}>
								<MuiAlert severity={this.props.message.type} variant="filled">
			    					{this.props.message.text}
			  					</MuiAlert>
						 	</Snackbar>
						</Container>
					</div>
					<div style={{ width: '100%', textAlign: '-webkit-center', backgroundColor: '#ffffff' }}>
						<div style={{ padding: 8, backgroundColor: '#ffffff', maxWidth: 500 }}>
							<h6 style={{ textAlign: 'center' }}>Parceiros</h6>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<img src={logo1} style={{ width: 150 }}/>
								<img src={logo1} style={{ width: 150 }}/>
							</div>
						</div>
					</div>
				</div>
			</ThemeProvider>
		);
	}
}

Auth = reduxForm({form: 'authForm'})(Auth);
const mapStateToProps = state => ({
	message: state.auth.message,
	modoTela: state.auth.modoTela,
	formularioValues: getFormValues('authForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ login, signup, initForm, setModoTela, esqueciSenha, redefinirsenha }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
