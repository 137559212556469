import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import imagemParceiro1 from '../assets/parceiros/banner1.png';
import imagemParceiro2 from '../assets/parceiros/banner2.png';
import imagemParceiro3 from '../assets/parceiros/banner3.png';
import imagemParceiro4 from '../assets/parceiros/banner4.png';

import {
	
} from './dashboardActions';
import Contato from './contato';
import Demanda from './demanda';
import DemandaGeracao from './demandaGeracao';
import Fatura from './fatura';
import Carga from './carga';
import SimuladorFatura from './simuladorFatura';

class Dashboard extends Component {

	state = {
		tela: 'principal',
		pesquisar: '',
		indexBanner: 0
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {

		const idInterval = setInterval(() => {
			this.setState({ ...this.state, indexBanner: this.state.indexBanner == 3 ? 0 : this.state.indexBanner + 1 });
		}, 5000);

		this.setState({
			...this.state,
			idInterval: idInterval
		})

    }

	componentDidUnmount() {

		clearInterval(this.state.idInterval);

    }

    render() {

		if (this.state.tela == 'principal') {
			return (
				<>
					<Content>
						<ContentCard>
							<ContentCardBody style={{ paddingTop: 8 }}>
								<div style={{ textAlign: 'center' }}>
									<img 
										src={[imagemParceiro1, imagemParceiro2, imagemParceiro3, imagemParceiro4][this.state.indexBanner]} 
										style={window.innerWidth < 500 ? { width: '100%' } : { height: 200 }} />
								</div>			
							</ContentCardBody>
						</ContentCard>
					</Content>

					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							padding: 12
						}}
						onClick={() => {
							
							this.setState({
								...this.state,
								tela: 'demanda'
							});

						}} >
						<div 
							style={{
								height: 40,
								width: 40,
								backgroundColor: '#47639e',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 20
							}}>
							<i class='fas fa-file-alt'
								style={{ 
									color: '#fff',
									fontSize: 22
								}} />
						</div>
						<div 
							style={{
								marginLeft: 12,
								fontSize: 18
							}}>
							Contratos de Demanda
						</div>
					</div>

					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							padding: 12
						}}
						onClick={() => {
							
							this.setState({
								...this.state,
								tela: 'demandaGeracao'
							});
							
						}} >
						<div 
							style={{
								height: 40,
								width: 40,
								backgroundColor: '#47639e',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 20
							}} >
							<i class='fas fa-house-damage'
								style={{ 
									color: '#fff',
									fontSize: 22
								}} />
						</div>
						<div 
							style={{
								marginLeft: 12,
								fontSize: 18
							}}>
							Demanda Geração
						</div>
					</div>

					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							padding: 12
						}}
						onClick={() => {
							
							this.setState({
								...this.state,
								tela: 'fatura'
							});
							
						}} >
						<div 
							style={{
								height: 40,
								width: 40,
								backgroundColor: '#47639e',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 20
							}} >
							<i class='fas fa-file'
								style={{ 
									color: '#fff',
									fontSize: 22
								}} />
						</div>
						<div 
							style={{
								marginLeft: 12,
								fontSize: 18
							}}>
							Fatura de Energia
						</div>
					</div>

					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							padding: 12
						}}
						onClick={() => {
							
							this.setState({
								...this.state,
								tela: 'carga'
							});
							
						}} >
						<div 
							style={{
								height: 40,
								width: 40,
								backgroundColor: '#47639e',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 20
							}} >
							<i class='fas fa-th-list'
								style={{ 
									color: '#fff',
									fontSize: 22
								}} />
						</div>
						<div 
							style={{
								marginLeft: 12,
								fontSize: 18
							}}>
							Cargas
						</div>
					</div>

					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							padding: 12
						}}
						onClick={() => {
							
							this.setState({
								...this.state,
								tela: 'simuladorFatura'
							});
							
						}} >
						<div 
							style={{
								height: 40,
								width: 40,
								backgroundColor: '#47639e',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 20
							}} >
							<i class='fas fa-calculator'
								style={{ 
									color: '#fff',
									fontSize: 22
								}} />
						</div>
						<div 
							style={{
								marginLeft: 12,
								fontSize: 18
							}}>
							Simulador de Fatura
						</div>
					</div>

					<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							padding: 12
						}}
						onClick={() => {
							
							this.setState({
								...this.state,
								tela: 'contato'
							});
							
						}} >
						<div 
							style={{
								height: 40,
								width: 40,
								backgroundColor: '#47639e',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 20
							}} >
							<i class='fas fa-phone-alt'
								style={{ 
									color: '#fff',
									fontSize: 22
								}} />
						</div>
						<div 
							style={{
								marginLeft: 12,
								fontSize: 18
							}}>
							Contatos
						</div>
					</div>
				</>
			);
		} else if (this.state.tela == 'demanda') {
			return <Demanda voltar={() => this.setState({ ...this.state, tela: 'principal' })} />
		} else if (this.state.tela == 'demandaGeracao') {
			return <DemandaGeracao voltar={() => this.setState({ ...this.state, tela: 'principal' })} />
		} else if (this.state.tela == 'fatura') {
			return <Fatura voltar={() => this.setState({ ...this.state, tela: 'principal' })} />
		} else if (this.state.tela == 'carga') {
			return <Carga voltar={() => this.setState({ ...this.state, tela: 'principal' })} />
		} else if (this.state.tela == 'simuladorFatura') {
			return <SimuladorFatura voltar={() => this.setState({ ...this.state, tela: 'principal' })} />
		} else if (this.state.tela == 'contato') {
			return <Contato voltar={() => this.setState({ ...this.state, tela: 'principal' })} />
		}
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.dashboard.aguardando
});
const mapDispatchToProps = dispatch => bindActionCreators({
	
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
