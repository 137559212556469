import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, setCliente } from '../../auth/authActions';
import imagemLogoLetsara from '../../assets/images/logo.png';
import imagemLogoReports from '../../assets/images/logo_reports_azul.png';
import imagemLogoReportsHistorico from '../../assets/images/logo_reports_historico_azul.png';
import imagemLogoConformidades from '../../assets/images/logo_conformidades_azul.png';
import imagemLogoTrack from '../../assets/images/logo_track_azul.png';
import consts from '../../consts';
import { event } from 'jquery';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false };
	}
	changeOpen() {
		this.setState({ open: !this.state.open });
	}
	render() {
		const { nome, usuario } = this.props.usuario;

		let logo = null;
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
				<div style={{ width: '100%', textAlign: 'center' }}>
					{imagemLogoLetsara &&
					<img src={imagemLogoLetsara} style={{ height: 25, marginLeft: 0, marginBottom: 0, marginTop: 2 }}/>}
				</div>
				<ul className="navbar-nav ml-auto" style={{ width: 40 }}>
					<li className="nav-item dropdown">
						<a className="nav-link" data-toggle="dropdown" href="#" onClick={event => event.preventDefault()}> 
							<i className="far fa-user"></i>
						</a>
						<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ maxWidth: 350, width: 350 }}>
							<span className="dropdown-item dropdown-header">Perfil</span>
							<div className="dropdown-divider"></div>
							<a href="#" className="dropdown-item" onClick={event => event.preventDefault()}>
								<i className="fas fa-user mr-2"></i>
								<span className="float-right text-muted text-sm">{nome}</span>
							</a>
						<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item" onClick={event => event.preventDefault()}>
							<i className="fas fa-envelope mr-2"></i>
							<span className="float-right text-muted text-sm">{usuario}</span>
						</a>
						<div className="dropdown-divider"></div>
							<a href="#" onClick={(event) => {
								event.preventDefault();
								this.props.logout();
								window.location = '/';
							}}
								className="btn btn-block btn-primary btn-sm">Sair</a>
						</div>
					</li>
				</ul>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ logout, setCliente }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

/*
<div className="navbar-custom-menu">
	<ul className="nav navbar-nav">
		<li onMouseLeave={() => this.changeOpen()}
			className={`dropdown user user-menu ${this.state.open ? 'open' :''}`}>
			<a href="javascript:;" onClick={() => this.changeOpen()}
				aria-expanded={this.state.open ? 'true' : 'false'}
				className="dropdown-toggle"
				data-toggle="dropdown">
				<i className="fa fa-user"/>
				<span className="hidden-xs">{nome}</span>
			</a>
			<ul className="dropdown-menu">
				<li className="user-header">
					<p>{nome}<small>{usuario}</small></p>
				</li>
				<li className="user-footer">
					<div className="pull-right">
						<a href="#" onClick={this.props.logout}
							className="btn btn-default btn-flat">Sair</a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>
*/
