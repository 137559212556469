import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

import { alterarSenha } from '../../auth/authActions';
import LabelAndInput from '../form/labelAndInput';
import Row from '../layout/row';
import Button from '../button/button';
import { Grid } from '@material-ui/core';

class Menu extends Component {

	state = { dadosLogin: {} };

	constructor(props) {
		super(props);

		// let idInterval = setInterval(() => {
		// 	if (this.props.usuario) {
		// 		this.props.carregarUsuario();
		// 		clearInterval(idInterval);
		// 	}
		// }, 2000);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		// if (this.props.usuario && this.props.usuario.modoCliente && (
		// 	selecionado == '/usuario' || selecionado == '/cliente' || selecionado == '/transportadora' || selecionado == '/embalagem'
		// 	|| selecionado == '/fornecedor'	|| selecionado == '/fornecedorEmbalagem' || selecionado == '/embalagemEntrada'
		// 	|| selecionado == '/embalagemSaidaMotivo' || selecionado == '/embalagemSaida' || selecionado == '/embalagemViagem'
		// 	|| selecionado == '/embalagemInventario' || selecionado == '/embalagemViagemComprovante' || selecionado == '/inventario')) {
		//
		// 	window.location = '/';
		// }

		return (
			<nav className="mt-2">
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					<MenuItem path='dashboard' label='App' icon='fas fa-chart-line' active={selecionado == '/'} />
				</ul>
			</nav>
		);
	}

	modalPrimeiroAcesso() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>Primeiro Acesso</h4>
						</div>
						<div class='modal-body' style={{ overflowY: 'scroll' }}>

							<Row alignCenter>
								<LabelAndInput
									name='senha'
									type="password"
									label='Senha *'
									placeholder='Informe a Senha'
									cols='12 12 6 6'
									readOnly={''}
									value={this.state.dadosLogin ? this.state.dadosLogin.senha : ''}
									onChange={data => this.setState({ ...this.state, dadosLogin: { ...this.state.dadosLogin, senha: data.target.value }})}  />
							</Row>
							<Row alignCenter>
								<LabelAndInput
									name='senha_confirmacao'
									type="password"
									label='Confirme sua Senha *'
									placeholder='Informe a Confirmação'
									cols='12 12 6 6'
									readOnly={''}
									value={this.state.dadosLogin ? this.state.dadosLogin.senha_confirmacao : ''}
									onChange={data => this.setState({ ...this.state, dadosLogin: { ...this.state.dadosLogin, senha_confirmacao: data.target.value }})} />
							</Row>
							<Row alignCenter>
								<Grid cols='12 12 6 6'>
									<Button
										text='Salvar nova Senha'
										type={'success'}
										icon={'fa fa-chevron-left'}
										disabled={!this.state.dadosLogin.senha || this.state.dadosLogin.senha != this.state.dadosLogin.senha_confirmacao}
										event={() => this.props.alterarSenha({ ...this.state.dadosLogin, id: this.props.usuarioCarregado.id })} />
								</Grid>
							</Row>

						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ alterarSenha }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
