import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
	getListaUnidade, getListaDemanda
} from './dashboardActions';

class DemandaGeracao extends Component {

	state = {
		pesquisar: '',
		indexBanner: 0
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {

		this.props.getListaUnidade();
		this.props.getListaDemanda(0);
		
    }

    render() {

		return (
			<>
				<div 
					style={{
						padding: 12
					}} >
						
					<Row alignCenter style={{ padding: 12, paddingRight: 24 }}>
						<Grid cols='12 12 6 4 3'>
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.voltar()} />
						</Grid>
					</Row>

					<Row>
						<Select
							cols='12 12 12 12'
							label='Unidade Consumidora'
							options={this.props.listaUnidade.map(item => ({ id: item.id, valor: (`${item.valor} ${this.props.sessao.acessoCliente ? '' : item.nome_cliente}`).trim() })) }
							value={this.state.id_unidade_consumidora}
							onChange={value => {
								this.setState({ ...this.state, id_unidade_consumidora: value });
								this.props.getListaDemanda(value);
							}} />
					</Row>

					{this.props.listaDemanda.filter(item => item.id_unidade == this.state.id_unidade_consumidora).map(item => (
						<div key={item.id} >
							<div style={{ paddingTop: 16, paddingBottom: 16 }}>
								<div style={{ color: '#000', fontSize: 14, fontWeight: 'bold' }}>Cliente:</div>
								<div style={{ color: '#000', fontSize: 14 }}>{item.nome_cliente}</div>
								<div style={{ color: '#000', fontSize: 14, fontWeight: 'bold' }}>Unidade Consumidora:</div>
								<div style={{ color: '#000', fontSize: 14 }}>{item.nome_unidade}</div>
								<div style={{ color: '#000', fontSize: 14, fontWeight: 'bold' }}>Vigência:</div>
								<div style={{ color: '#000', fontSize: 14 }}>{item.competencia_inicio_formatada} até {item.competencia_fim_formatada}</div>
								<div style={{ color: '#000', fontSize: 14, fontWeight: 'bold' }}>Aniversário:</div>
								<div style={{ color: '#000', fontSize: 14 }}>{item.competencia_aniversario_formatada}</div>
							</div>
								
							{(item.itens || []).map(mes => {
								let status = parseFloat(mes.geracao_energia) > parseFloat(mes.geracao_energia_lida) ? '#f44336' : '#43a047';
								return (
									<div key={mes.id} >
										<div style={{ paddingTop: 8, paddingBottom: 8 }}>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
												<div style={{ color: '#000', fontSize: 14, fontWeight: 'bold', textAlign: 'left', width: '35%' }}>Mês</div>
												<div style={{ color: '#000', fontSize: 14, fontWeight: 'bold', textAlign: 'center', width: '30%' }}>Contrato</div>
												<div style={{ color: '#000', fontSize: 14, fontWeight: 'bold', textAlign: 'center', width: '30%' }}>Lida</div>
												<div style={{ color: '#000', fontSize: 14, fontWeight: 'bold', textAlign: 'right', width: '5%' }}></div>
											</div>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
												<div style={{ color: '#000', fontSize: 14, textAlign: 'left', width: '35%' }}>{mes.competencia.split('-')[1]}/{mes.competencia.split('-')[0]}</div>
												<div style={{ color: '#000', fontSize: 14, textAlign: 'center', width: '30%' }}>{FormatUtils.formatarValorTelaDecimal(mes.geracao_energia, 2)}</div>
												<div style={{ color: '#000', fontSize: 14, textAlign: 'center', width: '30%' }}>{FormatUtils.formatarValorTelaDecimal(mes.geracao_energia_lida, 2)}</div>
												<div style={{ color: '#000', fontSize: 14, textAlign: 'right', width: '5%', paddingTop: 2 }}>
													<div style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: status }} />
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					))}
				</div>
			</>
		)
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.dashboard.aguardando,
	listaUnidade: state.dashboard.listaUnidade,
	listaDemanda: state.dashboard.listaDemanda
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaUnidade, getListaDemanda	
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DemandaGeracao)
