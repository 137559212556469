import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

import {
	getListaUnidade, getListaFatura
} from './dashboardActions';

class Fatura extends Component {

	state = {
		pesquisar: '',
		indexBanner: 0,
		mes: String(new Date().getMonth() + 1).padStart(2, '0'),
		ano: String(new Date().getFullYear())
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {

		this.props.getListaUnidade();
		this.props.getListaFatura(0, 0, 0);
		
    }

    render() {

		let meses = [
			{ id: '01', valor: 'Janeiro' }, { id: '02', valor: 'Fevereiro' }, { id: '03', valor: 'Março' }, { id: '04', valor: 'Abril' }, 
			{ id: '05', valor: 'Maio' }, { id: '06', valor: 'Junho' }, { id: '07', valor: 'Julho' }, { id: '08', valor: 'Agosto' }, 
			{ id: '09', valor: 'Setembro' }, { id: '10', valor: 'Outubro' }, { id: '11', valor: 'Novembro' }, { id: '12', valor: 'Dezembro' }
		];
		let anos = [];
		for (let ano = 2014; ano <= new Date().getFullYear(); ano++) {
			anos.push({ id: String(ano), valor: String(ano) });
		}

		return (
			<>
				<div 
					style={{
						padding: 12
					}} >
						
					<Row alignCenter style={{ padding: 12, paddingRight: 24 }}>
						<Grid cols='12 12 6 4 3'>
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.voltar()} />
						</Grid>
					</Row>

					<Row>
						<Select
							cols='12 12 12 12'
							label='Unidade Consumidora'
							options={this.props.listaUnidade.map(item => ({ id: item.id, valor: (`${item.valor} ${this.props.sessao.acessoCliente ? '' : item.nome_cliente}`).trim() })) }
							value={this.state.id_unidade_consumidora}
							onChange={value => {
								this.setState({ ...this.state, id_unidade_consumidora: value });
								this.props.getListaFatura(value, this.state.mes, this.state.ano);
							}} />

						<Select
							cols='12 12 12 12'
							label='Mês'
							options={meses}
							value={this.state.mes}
							onChange={value => {
								this.setState({ ...this.state, mes: value });
								this.props.getListaFatura(this.state.id_unidade_consumidora, value, this.state.ano);
							}} />

						<Select
							cols='12 12 12 12'
							label='Ano'
							options={anos}
							value={this.state.ano}
							onChange={value => {
								this.setState({ ...this.state, ano: value });
								this.props.getListaFatura(this.state.id_unidade_consumidora, this.state.mes, value);
							}} />
					</Row>

					<div style={{ overflowX: 'scroll' }}>
						{this.props.listaFatura.filter(item => item.id_unidade == this.state.id_unidade_consumidora).map(item => (
							<div key={item.id} style={{ paddingTop: 10, marginBottom: 50, minWidth: 700 }}>
								{(item.itens || []).map((mes, i) => {
									return (
										<div key={mes.id}>
											{i == 0 &&
											<div style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 4, paddingRight: 4 }}>
												<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
													<div style={{ color: '#000', backgroundColor: '#ffffff', fontSize: 14, fontWeight: 'bold', textAlign: 'left', width: 200 }}>Produto</div>
													<div style={{ color: '#000', backgroundColor: '#ffffff', fontSize: 14, fontWeight: 'bold', textAlign: 'center', width: 100 }}>Quantidade</div>
													<div style={{ color: '#000', backgroundColor: '#ffffff', fontSize: 14, fontWeight: 'bold', textAlign: 'center', width: 150 }}>Grandeza</div>
													<div style={{ color: '#000', backgroundColor: '#ffffff', fontSize: 14, fontWeight: 'bold', textAlign: 'center', width: 100 }}>Vl. Unit.</div>
													<div style={{ color: '#000', backgroundColor: '#ffffff', fontSize: 14, fontWeight: 'bold', textAlign: 'center', width: 100 }}>Imposto</div>
													<div style={{ color: '#000', backgroundColor: '#ffffff', fontSize: 14, fontWeight: 'bold', textAlign: 'center', width: 100 }}>Vl. Total</div>
												</div>
											</div>}
											<div style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 4, paddingRight: 4, backgroundColor: mes.cor }}>
												<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', minHeight: 50 }}>
													<div style={{ color: this.getCorFonte(mes.cor || '#ffffff'), fontSize: 14, textAlign: 'left', width: 200 }}>{mes.produto_descricao}</div>
													<div style={{ color: this.getCorFonte(mes.cor || '#ffffff'), fontSize: 14, textAlign: 'center', width: 100 }}>{FormatUtils.formatarValorTelaDecimal(mes.quantidade, 2)}</div>
													<div style={{ color: this.getCorFonte(mes.cor || '#ffffff'), fontSize: 14, textAlign: 'center', width: 150 }}>{mes.grandeza_descricao}</div>
													<div style={{ color: this.getCorFonte(mes.cor || '#ffffff'), fontSize: 14, textAlign: 'center', width: 100 }}>{FormatUtils.formatarValorTela(mes.valor_unitario, 6)}</div>
													<div style={{ color: this.getCorFonte(mes.cor || '#ffffff'), fontSize: 14, textAlign: 'center', width: 100 }}>{FormatUtils.formatarValorTelaDecimal(mes.impostos, 2)}</div>
													<div style={{ color: this.getCorFonte(mes.cor || '#ffffff'), fontSize: 14, textAlign: 'center', width: 100 }}>{FormatUtils.formatarValorTelaDecimal(mes.valor_total, 2)}</div>
												</div>
												<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
													
												</div>
											</div>
											<div style={{ height: 1, backgroundColor: this.getCorFonte(mes.cor || '#ffffff') }} />
										</div>
									);
								})}
							</div>
						))}
					</div>
				</div>
			</>
		)
	}

	getCorFonte(hex) {
		if (!hex) {
			return null;
		}
		if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}
		// convert 3-digit hex to 6-digits.
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			throw new Error('Invalid HEX color.');
		}
		let r = parseInt(hex.slice(0, 2), 16);
		let g = parseInt(hex.slice(2, 4), 16);
		let b = parseInt(hex.slice(4, 6), 16);
	
		if ((r * 0.299 + g * 0.587 + b * 0.114) > 186) {
			return '#000000';
		} else {
			return '#FFFFFF';
		}
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.dashboard.aguardando,
	listaUnidade: state.dashboard.listaUnidade,
	listaFatura: state.dashboard.listaFatura
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaUnidade, getListaFatura
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Fatura)
